<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-puzzle</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Maturaarbeiten</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2 mt-4"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        persistent-hint
        hint="Titel, Schüler*in oder LK"
        clearable
      ></v-text-field>
      <template v-slot:extension>
        <DivisionTabs
          align-with-title
          v-model="division"
          all
          style="width: auto; margin-right: 2rem"
        />
        <SchoolYearTabs v-model="schoolYear" />
      </template>
    </v-app-bar>
    <v-toolbar flat class="mb-4">
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="copyEventoData"
            color="info"
            v-bind="attrs"
            v-on="on"
            fab
            small
            depressed
            class="ml-2"
          >
            <v-icon>mdi-export</v-icon>E
          </v-btn>
        </template>
        {{ division ? division.code : "" }} Daten
        {{ search ? '(Suche nach "' + search + '")' : "" }} für Export nach
        Evento in Zwischenablage kopieren
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="copyPensenData"
            color="info"
            v-bind="attrs"
            v-on="on"
            fab
            small
            depressed
            class="ml-2"
          >
            <v-icon>mdi-export</v-icon>P
          </v-btn>
        </template>
        {{ division ? division.code : "" }} Daten
        {{ search ? '(Suche nach "' + search + '")' : "" }} für Export nach
        Pensenmanager in Zwischenablage kopieren
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            :to="{ name: 'ThesisSettings' }"
            v-on="on"
            v-bind="attrs"
            fab
            small
            depressed
            class="ml-2"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        Einstellungen
      </v-tooltip>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table :loading="loading" :headers="headers" :items="filtered">
        <template v-slot:item.student="{ item }">
          <PersonItem :value="item.student" />
        </template>
        <template v-slot:item.firstCoach="{ item }">
          <PersonItem :value="item.firstCoach" />
          <PersonItem v-if="item.secondCoach" :value="item.secondCoach" />
        </template>
        <template v-slot:item.assessor="{ item }">
          <PersonItem v-if="item.assessor" :value="item.assessor" />
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>
<script>
import DivisionTabs from "@/components/DivisionTabs";
import PersonItem from "@/components/PersonItem";
import SchoolYearTabs from "@/components/SchoolYearTabs";

import { comparePeople, searchPerson } from "common/utils/people.js";

export default {
  name: "Thesis",
  components: {
    DivisionTabs,
    PersonItem,
    SchoolYearTabs,
  },
  data() {
    return {
      eventoDialog: false,
      division: null,
      schoolYear: null,
      headers: [
        { text: "Schüler*in", value: "student", sort: comparePeople },
        { text: "Titel", value: "title" },
        { text: "Betreuer*in", value: "firstCoach", sort: comparePeople },
        { text: "Zweitlesung", value: "assessor", sort: comparePeople },
      ],
      items: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    eventoExport() {
      let result = "";
      this.filtered.forEach((item) => {
        if (item.eventoAnlassId != 0) {
          result += item.eventoAnlassId + "\t";
          result += item.eventoPersonId + "\t";
          result += "3520\t";
          result += item.title + "\n";
        }
      });
      return result;
    },
    pensenExport() {
      let teacherMap = new Map();
      this.filtered.forEach((item) => {
        // zweitlesung
        if (item.assessor) {
          if (!teacherMap.has(item.assessor.code)) {
            let newItemMap = new Map();
            newItemMap.set("coach", 0).set("team", 0).set("assessor", 0);
            teacherMap.set(item.assessor.code, newItemMap);
          }
          let itemMap = teacherMap.get(item.assessor.code);
          itemMap.set("assessor", itemMap.get("assessor") + 1);
        }
        // teambetreuung
        if (item.firstCoach && item.secondCoach) {
          // erster betreuer
          if (!teacherMap.has(item.firstCoach.code)) {
            let newItemMap = new Map();
            newItemMap.set("coach", 0).set("team", 0).set("assessor", 0);
            teacherMap.set(item.firstCoach.code, newItemMap);
          }
          let itemMap = teacherMap.get(item.firstCoach.code);
          itemMap.set("team", itemMap.get("team") + 1);
          // zweiter betreuer
          if (!teacherMap.has(item.secondCoach.code)) {
            let newItemMap = new Map();
            newItemMap.set("coach", 0).set("team", 0).set("assessor", 0);
            teacherMap.set(item.secondCoach.code, newItemMap);
          }
          itemMap = teacherMap.get(item.secondCoach.code);
          itemMap.set("team", itemMap.get("team") + 1);
        }
        // einzelbetreuung
        else if (item.firstCoach) {
          if (!teacherMap.has(item.firstCoach.code)) {
            let newItemMap = new Map();
            newItemMap.set("coach", 0).set("team", 0).set("assessor", 0);
            teacherMap.set(item.firstCoach.code, newItemMap);
          }
          let itemMap = teacherMap.get(item.firstCoach.code);
          itemMap.set("coach", itemMap.get("coach") + 1);
        }
      });

      // sortieren
      teacherMap = new Map([...teacherMap.entries()].sort());

      // ausgabe machen
      let result = "code, coach, team, assessor\n";
      const teacherMapIterator = teacherMap[Symbol.iterator]();
      for (const item of teacherMapIterator) {
        let key = item[0];
        let value = item[1];
        let line = `${key}, ${value.get("coach")}, ${value.get(
          "team"
        )}, ${value.get("assessor")}`;
        result += line + "\n";
      }
      return result;
    },
    filtered() {
      return this.items.filter(
        (item) =>
          (!this.division ||
            this.division.id === 0 ||
            (item.student.division &&
              this.division.id === item.student.division.id)) &&
          (!this.search ||
            (item.student && searchPerson(item.student, this.search)) ||
            (item.firstCoach && searchPerson(item.firstCoach, this.search)) ||
            (item.secondCoach && searchPerson(item.secondCoach, this.search)) ||
            (item.assessor && searchPerson(item.assessor, this.search)) ||
            (item.title && item.title.toLowerCase().includes(this.search)))
      );
    },
  },
  watch: {
    async schoolYear() {
      await this.getData();
    },
  },
  methods: {
    async copyEventoData() {
      const value = this.eventoExport;
      try {
        await navigator.clipboard.writeText(value);
        this.$root.showSuccess(
          "<strong>in Zwischenablage kopiert:</strong><br>" + value
        );
      } catch (err) {
        this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
      }
    },
    async copyPensenData() {
      const value = this.pensenExport;
      try {
        await navigator.clipboard.writeText(value);
        this.$root.showSuccess(
          "<strong>in Zwischenablage kopiert:</strong><br>" + value
        );
      } catch (err) {
        this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
      }
    },
    async getData() {
      if (this.schoolYear) {
        this.loading = true;

        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });

        this.loading = false;
      }
    },
  },
};
</script>
